.initsat-flex-width {
    display: inline-flex;
    align-items: end;
}
.initsat-flex-width .Polaris-Button--sizeSlim {
    height: 36px;
    margin-left: 15px;
}
.initsat-flex-width .Polaris-TextField__Backdrop{
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
}
.initsat-flex-width .Polaris-Button--primary {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.initsat-flex-align {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #ccc;
    padding: 15px;
    border-radius: 10px;
    margin-bottom: 10px;
}

.initsat-flex-align-conf{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
}
.initsat-flex-align-check{
    display: flex;
    align-items: center;
    border-radius: 10px;
}
.Polaris-Button--primary {
    width: auto;
    max-width: max-content;
}

.initsat-display-center{
    display:flex;
    align-items: center;
    justify-content: center;
    height: 100px;
}

.initsat-accordion-item {
    margin-bottom: 10px;
}

.initsat-accordion-header {
    background-color: transparent;
    padding: 10px 0;
    cursor: pointer;
    font-size: 18px;
    font-weight: bold;
    display: flex;
}

.initsat-accordion-content {
    padding: 10px 0;
}

.initsat-all{
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.initsat-section-cell {
    padding: 15px;
    background: #fff;
    border-radius: 9px;
    text-align: center;
    position: relative;
}
.initsat-active-label{
    background: green;
    color: #fff;
    padding: 5px 15px;
    line-height: normal;
    border-radius: 7px 7px 0px 0px;
    margin: -15px -15px 10px;
}

.initsat-icon-size i{
    font-size: 45px;
    margin-bottom: 0px;
}
.initsat-card-change .Polaris-LegacyCard {
    width:30%;
    margin-top: 0 !important;
}
#enable_app a , #enable_abandoned_cart a{
    color: #fff;
    text-decoration: none;
}
.initsat-accordion-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.Polaris-Page--fullWidth {
    padding-bottom: 30px !important;

}
.Polaris-LegacyCard {
    min-height: 138px;
}
.Polaris-Layout {
    padding-bottom: 10px;
}